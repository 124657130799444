'use client';
import { useEffect, useState } from 'react';
const Delay = ({ children, delay, fallback }) => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => setVisible(true), delay);
    }, [delay]);
    return visible ? children : fallback;
};
export default Delay;
