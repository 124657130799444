'use client';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { toRootCssVariables } from '@personly/libs-css';
const setCssRootVariables = (cssVariables) => {
    const rootCssVariables = toRootCssVariables(cssVariables);
    const root = document.querySelector(':root');
    for (const { key, value } of rootCssVariables) {
        root.style.setProperty(`--${key}`, value);
    }
};
const reduceCssRootVariables = (cssVariables) => cssVariables.reduce((acc, cur) => (Object.assign(Object.assign({}, acc), { [cur.key]: cur.value })), {});
const SchemesContext = createContext(null);
const SchemesProvider = ({ children, cookie, host, schemes, tld }) => {
    const [scheme, setScheme] = useState(schemes.scheme);
    const [cookieScheme, setCookieScheme] = useState(null);
    const schemeValid = useCallback((scheme) => schemes.schemes.map(({ id }) => id).includes(scheme), [schemes.schemes]);
    useEffect(() => {
        (async () => {
            const cookieScheme = await cookie.get('schemeHandle');
            if (cookieScheme && schemeValid(cookieScheme)) {
                setCookieScheme(cookieScheme);
            }
        })();
    }, [schemeValid, cookie]);
    const updateScheme = useCallback((scheme) => {
        if (scheme === 'SYSTEM') {
            for (const browserScheme of schemes.schemes) {
                if (!browserScheme.flags.browser)
                    continue;
                const media = `(prefers-color-scheme: ${browserScheme.id.toLowerCase()})`;
                if (window.matchMedia(media).matches) {
                    setCssRootVariables(browserScheme.cssVariables);
                    setScheme(browserScheme.id);
                    return;
                }
            }
        }
        else {
            const { cssVariables } = schemes.schemes.find(({ id }) => id === scheme);
            setCssRootVariables(cssVariables);
            setScheme(scheme);
        }
    }, [schemes.schemes]);
    const setSchemeHandle = useCallback((scheme) => {
        (async () => {
            const differs = cookieScheme !== scheme;
            const valid = !!cookieScheme && schemeValid(cookieScheme);
            const canChange = !cookieScheme || valid;
            if (differs && canChange) {
                const domain = await tld.getDomain(host);
                await cookie.set('schemeHandle', scheme, { domain });
                setCookieScheme(scheme);
                updateScheme(scheme);
            }
        })();
    }, [cookie, cookieScheme, schemeValid, updateScheme, host, tld]);
    useEffect(() => {
        if (cookieScheme && schemeValid(cookieScheme)) {
            updateScheme(cookieScheme);
        }
    }, [cookieScheme, schemeValid, updateScheme, scheme]);
    const cssVariables = useMemo(() => {
        if (scheme === 'SYSTEM') {
            if (typeof window !== 'undefined' && window) {
                for (const browserScheme of schemes.schemes) {
                    if (!browserScheme.flags.browser)
                        continue;
                    const media = `(prefers-color-scheme: ${browserScheme.id.toLowerCase()})`;
                    if (window.matchMedia(media).matches) {
                        return reduceCssRootVariables(browserScheme.cssVariables);
                    }
                }
            }
            else {
                return {};
            }
        }
        else {
            const { cssVariables } = schemes.schemes.find(({ id }) => id === scheme);
            return reduceCssRootVariables(cssVariables);
        }
    }, [schemes, scheme]);
    const schemeHandle = useMemo(() => cookieScheme || scheme, [cookieScheme, scheme]);
    const displaySchemeHandle = useMemo(() => (schemeHandle === 'SYSTEM' ? schemes.schemes[0].id : schemeHandle), [schemeHandle, schemes.schemes]);
    const value = useMemo(() => ({
        schemeHandle,
        setSchemeHandle,
        cssVariables,
        displaySchemeHandle,
    }), [setSchemeHandle, schemeHandle, cssVariables, displaySchemeHandle]);
    return (React.createElement(SchemesContext.Provider, { value: value }, children));
};
SchemesProvider.propTypes = {};
function useSchemes() {
    const context = useContext(SchemesContext);
    if (!context)
        throw new Error('useSchemes requires a context!');
    return context;
}
export { SchemesProvider, useSchemes };
