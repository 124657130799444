'use client';
import { Children, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
const defaultBreakpointCols = {
    default: 3,
    1400: 3,
    1200: 2,
    992: 2,
    768: 1,
    576: 1,
};
const reCalculateColumnCount = (breakpointColsObject) => {
    const windowWidth = (window && window.innerWidth) || Infinity;
    let matchedBreakpoint = Infinity;
    let columns = breakpointColsObject.default;
    for (let breakpoint in breakpointColsObject) {
        const optBreakpoint = parseInt(breakpoint);
        const isCurrentBreakpoint = optBreakpoint > 0 && windowWidth <= optBreakpoint;
        if (isCurrentBreakpoint && optBreakpoint < matchedBreakpoint) {
            matchedBreakpoint = optBreakpoint;
            columns = breakpointColsObject[breakpoint];
        }
    }
    columns = Math.max(1, parseInt(columns) || 1);
    return columns;
};
const Masonry = ({ children, breakpointCols = defaultBreakpointCols, gap = 'normal', }) => {
    const [columnCount, setColumnCount] = useState(breakpointCols.default);
    useEffect(() => {
        const newColumnCount = reCalculateColumnCount(breakpointCols);
        setColumnCount(newColumnCount);
        const interval = setInterval(() => {
            const newColumnCount = reCalculateColumnCount(breakpointCols);
            setColumnCount(newColumnCount);
        }, 300);
        return () => clearInterval(interval);
    }, [setColumnCount, breakpointCols]);
    const itemsInColumns = useCallback(() => {
        const currentColumnCount = columnCount;
        const itemsInColumns = new Array(currentColumnCount);
        const items = Children.toArray(children);
        for (let i = 0; i < items.length; i++) {
            const columnIndex = i % currentColumnCount;
            if (!itemsInColumns[columnIndex]) {
                itemsInColumns[columnIndex] = [];
            }
            itemsInColumns[columnIndex].push(items[i]);
        }
        return itemsInColumns;
    }, [children, columnCount]);
    const renderColumns = useMemo(() => {
        const childrenInColumns = itemsInColumns();
        const columnWidth = `${100 / childrenInColumns.length}%`;
        return childrenInColumns.map((items, i) => {
            return (React.createElement("div", { style: { width: columnWidth }, key: i }, items));
        });
    }, [itemsInColumns]);
    const classNames = ['flex', 'w-auto'];
    if (gap === 'small')
        classNames.push('gap-2');
    if (gap === 'normal')
        classNames.push('gap-3');
    return React.createElement("div", { className: classNames.join(' ') }, renderColumns);
};
export default Masonry;
