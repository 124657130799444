'use client';
import React from 'react';
import { Icon } from '../index.js';
const CardButton = ({ children, disabled = false, icon, onClick = null, type = 'button', }) => {
    const classNames = [
        'w-full',
        'rounded',
        'bg-card-background-color',
        'text-card-text-color',
        'border',
        'border-card-border-color',
        'py-1.5',
    ];
    if (disabled)
        classNames.push('opacity-60');
    return (React.createElement("button", { type: type, disabled: disabled, onClick: onClick, className: classNames.join(' ') },
        React.createElement("span", { className: "mr-2" },
            React.createElement(Icon, { icon: icon, type: "light", size: 'lg' })),
        children));
};
export default CardButton;
