'use client';
import React from 'react';
import { useMenus } from '@personly/libs-providers';
const MenuLinks = ({ children }) => {
    const { isOpen } = useMenus();
    const classNames = ['hidden', 'flex-col', 'lg:flex'];
    if (isOpen)
        classNames.push('!flex');
    return React.createElement("div", { className: classNames.join(' ') }, children);
};
export default MenuLinks;
