import { kebabCase } from 'change-case';

const toRootCssVariables = (cssVariables) =>
  cssVariables.reduce((acc, { key, value }) => {
    if (value.length > 1) {
      acc.push(
        ...value.reduce(
          (a, b, i) => [...a, { key: `${kebabCase(key)}-${i}`, value: b }],
          []
        )
      );
    } else acc.push({ key: kebabCase(key), value: value[0] });

    return acc;
  }, []);

const toPreferSchemeCss = (
  rootCss,
  scheme
) => `@media (prefers-color-scheme: ${scheme}) {
${rootCss}
}`;

const toRootCss = (cssVariables, numIndent = 0) => {
  const rootCssVariables = toRootCssVariables(cssVariables);
  const indent = ' ';

  const outerIndent = indent.repeat(numIndent);
  const innerIndent = indent.repeat(numIndent + 2);

  return `${outerIndent}:root {
${rootCssVariables
  .reduce(
    (acc, cur) => [...acc, `${innerIndent}--${cur.key}: ${cur.value};`],
    []
  )
  .join(`\n`)}
${outerIndent}}`;
};

const toTailwindCssVariables = (cssVariables) =>
  cssVariables
    .reduce((acc, { key, value }) => {
      if (value.length > 1) {
        acc.push(
          ...value.reduce(
            (a, _, i) => [
              ...a,
              {
                key: `${kebabCase(key)}${i}`,
                value: `var(--${kebabCase(key)}-${i})`,
              },
            ],
            []
          )
        );
      } else
        acc.push({
          key: kebabCase(key),
          value: `var(--${kebabCase(key)})`,
        });

      return acc;
    }, [])
    .reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {});

const toCss = ({ schemes, scheme }) => {
  if (scheme === 'SYSTEM') {
    return schemes
      .filter(({ id }) => id !== 'SYSTEM')
      .reduce(
        (acc, cur) => [
          ...acc,
          toPreferSchemeCss(
            toRootCss(cur.cssVariables, 2),
            cur.id.toLowerCase()
          ),
        ],
        []
      )
      .join(`\n\n`);
  }

  const activeScheme = schemes.find(({ id }) => id === scheme);

  return toRootCss(activeScheme.cssVariables);
};

const gatherCssVariables = (schemes) =>
  schemes.reduce((cssVariables, scheme) => {
    for (const cssVariable of scheme.cssVariables) {
      const i = cssVariables.findIndex(({ key }) => key === cssVariable.key);
      if (i === -1) cssVariables.push(cssVariable);
    }

    return cssVariables;
  }, []);

export {
  gatherCssVariables,
  toCss,
  toRootCssVariables,
  toTailwindCssVariables,
};
