'use client';
import React from 'react';
import classnames from 'classnames';
import Icon from '../icon';
const color = (type) => {
    const classNames = [
        'flex',
        'rounded-tl-lg',
        'rounded-bl-lg',
        'p-2',
        'bg-toast-icon-background-color',
        'self-stretch',
        'items-center',
    ];
    if (type === 'error') {
        classNames.push('text-toast-icon-error-color');
    }
    else if (type === 'success') {
        classNames.push('text-toast-icon-success-color');
    }
    else
        classNames.push('text-toast-icon-notice-color');
    return classNames.join(' ');
};
const icons = {
    error: 'circleExclamation',
    success: 'circleCheck',
    blank: 'triangleExclamation',
    custom: 'triangleExclamation',
    loading: 'triangleExclamation',
};
const icon = (type) => icons[type];
const buttonClassnames = classnames('border-0', 'bg-toast-background-color', 'text-xs', 'justify-end', 'text-toast-dismiss-color', 'm-1');
const Toast = ({ children, dismiss, message, type }) => (React.createElement("div", { className: 'flex flex-row flex-1 items-center' },
    React.createElement("div", { className: color(type) },
        React.createElement(Icon, { icon: icon(type), type: "solid", size: "lg" })),
    React.createElement("div", { className: "flex flex-col flex-1" },
        React.createElement("div", { className: "pt-4" }, message),
        React.createElement("div", { className: "flex flex-1 justify-end" },
            React.createElement("button", { className: buttonClassnames, onClick: dismiss }, children)))));
export default Toast;
