'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
const Textarea = (_a) => {
    var { invalid = false, disabled = false } = _a, props = __rest(_a, ["invalid", "disabled"]);
    const classNames = [
        'bg-input-background-color',
        'text-input-text-color',
        'block',
        'w-full',
        'py-1.5',
        'px-2.5',
        'bg-clip-padding',
        'rounded',
        'border',
        'border-input-border-color',
        'disabled:opacity-60',
        'disabled:outline-none',
        'focus:outline-none',
        'focus:border',
        'focus:border-input-focus-color',
        'focus:bg-input-background-color',
        'focus:text-input-focus-color',
        'placeholder:text-input-placeholder-text-color',
    ];
    if (invalid) {
        classNames.push('!border-input-border-invalid-color');
        classNames.push('!focus:border-input-border-invalid-color');
    }
    return (React.createElement("textarea", Object.assign({ readOnly: disabled, autoComplete: "off", className: classNames.join(' '), disabled: disabled }, props)));
};
export default Textarea;
