'use client';
import React from 'react';
import classnames from 'classnames';
import Icon from '../icon/index.js';
const className = classnames('flex', 'flex-row', 'background-input-background-color', 'border', 'border-input-border-color', 'rounded', 'p-2', 'gap-2');
const makeTestId = (id) => ['toggle', id].filter((i) => !!i).join('-');
const Toggle = ({ children, icon, onClick, id = null }) => (React.createElement("div", { className: className },
    React.createElement("div", { onClick: onClick, className: "flex items-center cursor-pointer text-input-icon-color", "data-testid": makeTestId(id) },
        React.createElement(Icon, { size: "lg", icon: icon, type: "solid" })),
    React.createElement("div", { className: "text-xs leading-normal" }, children)));
export default Toggle;
