'use client';

import Image from 'next/image';

import { useSchemes } from '@personly/libs-providers';

interface Props {
  priority?: boolean;
  srcSets: any;
  className?: string;
  sizes?: string;
}

const Picture = ({ priority = false, srcSets, className, sizes }: Props) => {
  const { displaySchemeHandle, schemeHandle } = useSchemes();

  return (
    <picture>
      {schemeHandle === 'SYSTEM' &&
        Object.keys(srcSets).map((schemeHandle) => (
          <source
            key={srcSets[schemeHandle].src}
            srcSet={srcSets[schemeHandle].src}
            media={`(prefers-color-scheme: ${schemeHandle.toLowerCase()})`}
            width={srcSets[schemeHandle].width}
            height={srcSets[schemeHandle].height}
          />
        ))}
      <Image
        alt={srcSets[displaySchemeHandle].alt}
        className={className}
        height={srcSets[displaySchemeHandle].height}
        priority={priority}
        sizes={sizes}
        src={srcSets[displaySchemeHandle].src}
        width={srcSets[displaySchemeHandle].width}
      />
    </picture>
  );
};

export default Picture;
